import { BOTTOM_TOOLBAR_HEIGHT } from "./MobileToolbar/MobileToolbar";

export const scrollNodeAboveKeyboard = (
  scrollElement: HTMLElement,
  nodeElement: HTMLElement,
  headerElement?: HTMLElement | null,
  keyboardHeight: number = window.lastIosKeyboardHeight
) => {
  const keyboardWithToolbarHeight = keyboardHeight + BOTTOM_TOOLBAR_HEIGHT;
  const nodeOffset = nodeElement.offsetTop;
  const scrollTop = scrollElement.scrollTop;
  const visibleArea =
    scrollElement.clientHeight -
    keyboardWithToolbarHeight +
    (headerElement?.clientHeight || 0);
  const relativeNodePosition =
    nodeOffset - scrollTop + nodeElement.clientHeight;
  const isHiddenByKeyboard = relativeNodePosition > visibleArea;

  if (isHiddenByKeyboard) {
    scrollElement.scrollTo({
      top: scrollTop + relativeNodePosition - visibleArea,
      behavior: "instant",
    });
  }
};

export const isKeyboardHidden = (
  scrollElement: HTMLElement,
  nodeElement: HTMLElement,
  scrollTop: number,
  keyboardHeight: number = window.lastIosKeyboardHeight
) => {
  const keyboardWithToolbarHeight = keyboardHeight + BOTTOM_TOOLBAR_HEIGHT;
  const nodeOffset = nodeElement.offsetTop;
  const visibleArea = scrollElement.clientHeight - keyboardWithToolbarHeight;
  const relativeNodePosition =
    nodeOffset - scrollTop + nodeElement.clientHeight;
  return relativeNodePosition > visibleArea;
};
