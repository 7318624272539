import { v4 } from "uuid";
import { EfNodeType } from "../graphql";
import { createNode } from "./sync";
import { db } from "../db";
import { updateTagAndItsChildrenInCache } from "../cache/tagCache";

export async function createTagNode(
  id: string = v4(),
  titleText: string,
  parentId: string | null = null
) {
  const existingNodes = await db.nodes
    .where("nodeType")
    .equals(EfNodeType.Tag)
    .toArray();
  //this check makes sure that a tag with the same name will not be created again
  if (
    !!existingNodes.find(
      ({ titleText: title, deleted, parentId: currParentId }) =>
        title?.toLowerCase() === titleText.toLowerCase() &&
        !deleted &&
        currParentId === (parentId ?? null)
    )
  ) {
    return console.warn(
      "we are not creating a new tag becuase it already exists"
    );
  }
  await createNode({
    id,
    nodeType: EfNodeType.Tag,
    contentText: "",
    titleText,
    parentId,
    position: null,
    tagIds: [],
    referencedPageIds: [],
    properties: {
      collapsed: false,
    },
  });
  updateTagAndItsChildrenInCache(id);
}

export function sortNodesBasedOnRecency<
  T extends {
    titleText: string | null;
    fullName?: string;
    relatedNodesModifiedTimeStamp: number;
  }
>(nodes: { [key in EfNodeType]?: T[] }, cleanedQuery: string) {
  [EfNodeType.Tag, EfNodeType.Contact].forEach((nodeType) => {
    if (nodes[nodeType]) {
      nodes[nodeType] = nodes[nodeType].sort((a, b) => {
        const aLength = Math.abs(
          cleanedQuery.length - ((a.fullName || a.titleText || "").length || 0)
        );
        const bLength = Math.abs(
          cleanedQuery.length - ((b.fullName || b.titleText || "").length || 0)
        );
        if (!cleanedQuery || (aLength > 2 && bLength > 2)) {
          if (
            (b.relatedNodesModifiedTimeStamp || 0) ===
            (a.relatedNodesModifiedTimeStamp || 0)
          ) {
            return (a.fullName || a.titleText || "").localeCompare(
              b.fullName || b.titleText || ""
            );
          }
          return (
            (b.relatedNodesModifiedTimeStamp || 0) -
            (a.relatedNodesModifiedTimeStamp || 0)
          );
        }
        if (aLength === bLength) {
          if (
            (b.relatedNodesModifiedTimeStamp || 0) ===
            (a.relatedNodesModifiedTimeStamp || 0)
          ) {
            return (a.fullName || a.titleText || "").localeCompare(
              b.fullName || b.titleText || ""
            );
          }
          return (
            (b.relatedNodesModifiedTimeStamp || 0) -
            (a.relatedNodesModifiedTimeStamp || 0)
          );
        }
        return aLength - bLength;
      });
    }
  });
  return nodes;
}
