import * as Sentry from "@sentry/browser";
import { BroadcastChannel, createLeaderElection } from "broadcast-channel";
import { useCallback, useEffect, useRef, useState } from "react";
import { pull, push, pushFiles } from "../utils";
import { debugSync } from "../utils/debug";
import { checkSchemaVersion } from "../db";
import { TabsMessageType } from "../types";
import { useProxyRef } from "./useProxyRef";

// has to br string because we are checking this version with version coming in response header(which will be string).
export const EF_SCHEMA_VERSION = "3";

export const channel = new BroadcastChannel("sync");
const elector = createLeaderElection(channel);

const broadcastHideLoader = () => {
  channel.postMessage(TabsMessageType.HIDE_LOADER);
};

const broadcastVersionMissmatchPrompt = () => {
  channel.postMessage(TabsMessageType.VERSION_MISSMATCH);
};

elector.onduplicate = () => {
  location.reload();
};
// @ts-ignore
window.push = push;
// @ts-ignore
window.pull = pull;

export function useAutoSync(setIsVersionMissmatch: (val: boolean) => void) {
  const syncingRef = useRef(false);
  const uploadingRef = useRef(false);

  const [showLoader, setShowLoader] = useState(true);
  const showLoaderRef = useRef(showLoader);

  const [totalNodes, _setTotalNodes] = useState(0);
  const [nodesLeftToPull, _setNodesLeftToPull] = useState(0);
  const totalNodesProxy = useProxyRef(totalNodes);
  const nodesLeftProxy = useProxyRef(nodesLeftToPull);

  const broadcastValue = (messageType: TabsMessageType, value: number) =>
    channel.postMessage(`${messageType},${value}`);
  const setTotalNodes = (total: number) => {
    // Whenever the leader sets total nodes state we also broadcast to all other tabs
    _setTotalNodes(total);
    if (elector.isLeader) broadcastValue(TabsMessageType.TOTAL_NODES, total);
  };
  const setNodesLeftToPull = (left: number) => {
    // Whenever the leader sets nodes left to pull state we also broadcast to all other tabs
    _setNodesLeftToPull(left);
    if (elector.isLeader)
      broadcastValue(TabsMessageType.NODES_LEFT_TO_PULL, left);
  };

  /**
   * This function is to reload other tabs when there is schema version variable change which is present in db/index.ts file.
   *  we are reloading to have updated files in all tabs so that there is no version missmatch in different tabs.
   */
  const setLoaderAfterSchemaCheck = (show: boolean) => {
    if (show) {
      channel.postMessage(TabsMessageType.RELOAD);
      setShowLoader(true);
    }
  };

  const sync = useCallback(
    async (isFirst?: boolean) => {
      if (syncingRef.current) return;

      syncingRef.current = true;

      /**
       * Pulls nodes and updates the loader
       * @returns nodes left to pull
       */
      const pullAndUpdateLoader = async (firstPull?: boolean) => {
        const { nodesLeftToPull, pulledNodes, efSchemaVersion } = await pull();
        if (efSchemaVersion !== EF_SCHEMA_VERSION) {
          broadcastVersionMissmatchPrompt();
          setIsVersionMissmatch(true);
          return;
        }
        // If we have no new nodes, we can assume we have finished initial pull
        if (nodesLeftToPull === 0) {
          setShowLoader(false);
          if (showLoaderRef.current) {
            broadcastHideLoader();
            showLoaderRef.current = false;
          }
        } else if (showLoader && nodesLeftToPull !== null) {
          if (firstPull) {
            setTotalNodes(nodesLeftToPull);
          }
          setNodesLeftToPull(nodesLeftToPull - pulledNodes);
        }
        return nodesLeftToPull;
      };

      try {
        await push();
        if (isFirst) {
          // If after first push, check for schema change
          await checkSchemaVersion(setLoaderAfterSchemaCheck);
        }

        let nodesLeft = await pullAndUpdateLoader(isFirst);
        while (isFirst && nodesLeft && nodesLeft > 0) {
          // If we are on first sync, continue pulling until we have all nodes
          nodesLeft = await pullAndUpdateLoader();
        }
      } catch (e) {
        console.log("sync fail with:", e);
        Sentry.captureException(e);
        if (
          isFirst &&
          e instanceof Error &&
          e.message === "Network request failed"
        ) {
          // If first sync failed because of a network request, try again
          syncingRef.current = false;
          return sync(isFirst);
        }
      }

      syncingRef.current = false;
    },
    [pull, push]
  );

  const upload = useCallback(async () => {
    // Only run one upload iteration at a time
    // This is separated from sync in-order to not block it
    if (uploadingRef.current) return;

    try {
      uploadingRef.current = true;
      await pushFiles();
    } catch (e) {
      console.log("push file fail with", e);
    }

    uploadingRef.current = false;
  }, [pushFiles]);

  useEffect(() => {
    let interval: NodeJS.Timer;

    elector.awaitLeadership().then(() => {
      debugSync("tab aquired leadership");
      // sync on load
      sync(true);
      // upload files
      upload();
      // sync on interval
      interval = setInterval(() => {
        sync();
        upload();
      }, 2000 * 1);
    });

    // listen for messages from other tabs
    channel.onmessage = (message: string) => {
      const isLeader = elector.isLeader;
      const showLoader = showLoaderRef.current;
      const [type, payload] = message.split(",");
      switch (type) {
        case TabsMessageType.HIDE_LOADER:
          if (isLeader) return;
          // If tab is not leader, and loader is shown, hide it
          setShowLoader(false);
          break;
        case TabsMessageType.VERSION_MISSMATCH:
          if (isLeader) return;
          // If tab is not leader, and loader is shown, hide it
          setIsVersionMissmatch(true);
          break;
        case TabsMessageType.NEW_CLIENT_PING:
          if (!isLeader) return;
          // If tab is leader, and loader is hidden, broadcast to other tabs
          if (!showLoader) broadcastHideLoader();
          else {
            // When a new client connects, broadcast the progress
            if (elector.isLeader) {
              broadcastValue(
                TabsMessageType.TOTAL_NODES,
                totalNodesProxy.current
              );
              broadcastValue(
                TabsMessageType.NODES_LEFT_TO_PULL,
                nodesLeftProxy.current
              );
            }
          }
          break;
        case TabsMessageType.RELOAD:
          window.location.reload();
          break;
        case TabsMessageType.TOTAL_NODES:
          if (isLeader) return;
          const parsedTotal = Number(payload);
          if (isNaN(parsedTotal)) return;
          setTotalNodes(parsedTotal);
          break;
        case TabsMessageType.NODES_LEFT_TO_PULL:
          if (isLeader) return;
          const parsedLeft = Number(payload);
          if (isNaN(parsedLeft)) return;
          setNodesLeftToPull(parsedLeft);
          break;
        default:
          console.warn("Unkown message", message);
          break;
      }
    };

    // If not the leader, ping him that a new tab connected
    if (!elector.isLeader) {
      checkSchemaVersion((show) => {
        setLoaderAfterSchemaCheck(show);
        // only posting message when there is no schema change
        if (!show) channel.postMessage(TabsMessageType.NEW_CLIENT_PING);
      });
    }

    return () => {
      clearInterval(interval);
      elector.die();
    };
  }, []);

  return { sync, showLoader, totalNodes, nodesLeftToPull };
}
