import { isNumber, pick } from "lodash";
import invariant from "tiny-invariant";
import { db } from "../db";
import {
  ApplyPatch,
  EfNode,
  EfNodeData,
  EfNodeEditorData,
  FileNodeData,
} from "../types";
import { debugSync } from "./debug";
import { diffEditorNodes } from "./diffEditorNodes";
import { removeNodesFromFileQueue } from "./fileUtils";
import { findNodeDescendents } from "./findNodeDescendents";
import { EfNodeType } from "../graphql";
import { getParentNodes } from "./nodes";
import { applyPatchToNode, calculatePatch } from ".";
import { Transaction } from "dexie";
import {
  deleteTagAndItsChildrenInCache,
  updateTagCacheForKey,
} from "../cache/tagCache";

export function getNodeEditorData(node: EfNode): EfNodeEditorData {
  return pick(node, [
    "id",
    "nodeType",
    "parentId",
    "position",
    "titleText",
    "contentText",
    "tagIds",
    "referencedPageIds",
    "mentionIds",
    "fileIds",
    "properties",
    "rootId",
  ]);
}

export function getNodeData(node: EfNode): EfNodeData {
  return {
    ...getNodeEditorData(node),
    ...pick(node, ["deleted", "workspaceId", "version", "clientModifiedTime"]), // not picking modifiedTime and createdTime as these are not required for patch call
  };
}

export async function applyServerUpdate(data: EfNodeData) {
  const node = await db.nodes.get(data.id);

  if (node?.oldNodeData) {
    // Node exists locally and has pending changes
    const clientPatch = calculatePatch(node.oldNodeData, node, [
      "version",
      "createdTime",
      "modifiedTime",
      "properties.is_system_created",
      "properties.isSystemCreated",
    ]);
    debugSync("rebase server update [%s] [%j]", data.id, clientPatch);
    const newDocument = applyPatchToNode(data, clientPatch);
    // copy computed fields from old node
    newDocument.computed = node.computed;
    await db.nodes.put({
      ...newDocument,
      oldNodeData: getNodeData(data),
      clientTimestamp: Date.now(),
      relatedNodesModifiedTimeStamp: node.relatedNodesModifiedTimeStamp,
    });

    if (shouldUpdateComputedFields(node, newDocument)) {
      await updateComputedFields(newDocument);
    }
    await updateRelatedNodesModifiedTimeIfRequired(data.id);
  } else {
    // Node either does not exist locally, or node exists locally without pending changes
    if (
      isNumber(node?.version) &&
      isNumber(data.version) &&
      node!.version >= data.version
    ) {
      debugSync("skip server update, same version [%s]", data.id);
      return;
    }

    debugSync("apply server update [%s]", data.id);

    await db.nodes.put({
      ...data,
      computed: node ? node.computed : {},
      clientTimestamp: node ? node.clientTimestamp : null,
      oldNodeData: null,
      relatedNodesModifiedTimeStamp: node?.relatedNodesModifiedTimeStamp || 0,
    });

    if (!node || shouldUpdateComputedFields(node, data)) {
      await updateComputedFields(data);
    }
    await updateRelatedNodesModifiedTimeIfRequired(data.id);
  }
}

export async function applyServerUpdateBulk(nodes: EfNodeData[]) {
  return Promise.all([
    // Apply server update
    db.transaction("readwrite", db.nodes, async () => {
      return Promise.all(nodes.map((node) => applyServerUpdate(node)));
    }),
    // Remove uploaded files from file queue as we no longer need to use them as tmp src
    db.transaction("readwrite", db.fileQueue, async () => {
      return Promise.all(removeNodesFromFileQueue(nodes));
    }),
  ]);
}

export async function persistDiff(diff: ReturnType<typeof diffEditorNodes>) {
  for (const data of diff.createdNodes) {
    await createNode(data);
  }
  for (const data of diff.updatedNodes) {
    await updateNode(data);
  }
  for (const data of diff.deletedNodes) {
    await deleteNode(data);
  }
}

export async function uploadToQueue(data: FileNodeData) {
  debugSync("create node [%s]", data.id);

  const workspaceId = localStorage.getItem("workspaceId");
  invariant(workspaceId, "Workspace ID is not defined");

  await db.fileQueue.put({ ...data, uploaded: 0 });
}

export async function createNode(data: EfNodeEditorData) {
  await db.transaction("readwrite", db.nodes, async () => {
    debugSync("create node [%s]", data.id);

    const workspaceId = localStorage.getItem("workspaceId");
    invariant(workspaceId, "Workspace ID is not defined");

    const node = await db.nodes.get(data.id);

    // If node is marked as deleted it means it has passed redo, so we will update it and mark as not deleted
    invariant(node?.deleted || !node, "Node should not exist");

    await db.nodes.put({
      ...data,
      computed: {},
      deleted: false,
      workspaceId: node?.workspaceId ?? workspaceId,
      version: node?.version ?? 0,
      clientTimestamp: Date.now(),
      oldNodeData: node?.oldNodeData ?? {},
      clientModifiedTime: new Date(),
      rootId: node?.rootId || (await computeRootNode(data)),
      relatedNodesModifiedTimeStamp: node?.relatedNodesModifiedTimeStamp || 0,
    });

    await updateComputedFields(data);
    await updateRelatedNodesModifiedTimeIfRequired(data.id, Date.now());
  });
}

export async function updateNode(data: EfNodeEditorData) {
  await db.transaction("readwrite", db.nodes, async () => {
    debugSync("update node [%s]", data.id);

    const node = await db.nodes.get(data.id);
    invariant(node, "Node should exist");

    await db.nodes.put({
      ...data,
      computed: node.computed,
      deleted: node.deleted,
      workspaceId: node.workspaceId,
      version: node.version,
      oldNodeData: node.oldNodeData ?? getNodeData(node),
      clientTimestamp: Date.now(),
      clientModifiedTime: new Date(),
      relatedNodesModifiedTimeStamp: node.relatedNodesModifiedTimeStamp,
    });

    if (shouldUpdateComputedFields(node, data)) {
      await updateComputedFields(data);
    }
    await updateRelatedNodesModifiedTimeIfRequired(data.id, Date.now());
  });
}

export async function deleteNode(data: Pick<EfNodeEditorData, "id">) {
  await db.transaction("readwrite", db.nodes, async () => {
    debugSync("delete node [%s]", data.id);

    const node = await db.nodes.get(data.id);
    invariant(node, "Node should exist");

    await db.nodes.put({
      ...node,
      deleted: true,
      oldNodeData: node.oldNodeData ?? getNodeData(node),
      clientTimestamp: Date.now(),
      clientModifiedTime: new Date(),
    });

    await updateComputedFields({ ...node, deleted: true });
  });
}

export async function deleteNodeAndChildren(data: EfNodeEditorData) {
  const descendents = await findNodeDescendents(data.id);
  await Promise.all([
    deleteNode(data),
    ...descendents.map((id) => deleteNode({ id })),
  ]);
}

export async function applyPushPatch(patch: ApplyPatch, nodes: EfNode[]) {
  if (patch.__typename === "ApplyPatchSuccess") {
    const id = patch.efNode.id;

    const oldNode = nodes.find((n) => n.id === id);
    const newNode = await db.nodes.get(id);

    // If node clientTimestamp was changed during push, do not remove old node data
    if (oldNode?.clientTimestamp !== newNode?.clientTimestamp) {
      await db.nodes.update(patch.efNode.id, { oldNodeData: oldNode });
      debugSync("node changed while pushing [%s]", id);
      return;
    }

    debugSync("apply patch success [%s]", id);
    await db.nodes.update(patch.efNode.id, { oldNodeData: null });
  }
  if (patch.__typename === "ApplyPatchError") {
    // update timestamp to retry on next push
    await db.nodes.update(patch.id, { clientTimestamp: Date.now() });

    debugSync("apply patch error [%s] [%s]", patch.id, patch.message);
    return;
  }
}

type ComputedFieldsInput = {
  id: string;
  parentId: string | null;
  position: string | null;
  deleted?: boolean;
  properties?: {
    collapsed?: boolean;
  } | null;
  nodeType: EfNodeType;
};

function shouldUpdateComputedFields(
  oldData: ComputedFieldsInput,
  newData: ComputedFieldsInput
) {
  return (
    oldData.parentId !== newData.parentId ||
    oldData.position !== newData.position ||
    oldData.deleted !== newData.deleted ||
    (oldData.properties?.collapsed !== newData.properties?.collapsed &&
      newData.nodeType !== EfNodeType.Page)
  );
}

async function getComputedFields(node: ComputedFieldsInput, tx?: Transaction) {
  const parent = node.parentId
    ? await db.getTable("nodes", tx).get(node.parentId)
    : null;

  let path: string | null = null;
  let visible: 1 | null = null;
  let pathInPage: string | null = null;

  const segment = `${node.position}#${node.id}`;

  if ([EfNodeType.Page, EfNodeType.ThoughtPad].includes(node.nodeType)) {
    pathInPage = `/null:${node.id}`;
  } else {
    pathInPage = `${parent?.computed.pathInPage}/${segment}`;
  }

  if (!node.parentId) {
    path = `/${segment}`;
  } else if (parent?.computed.path) {
    path = `${parent?.computed.path}/${segment}`;
  }

  if (!node.parentId) {
    visible = 1;
  } else if (node.deleted) {
    visible = null;
  } else if (
    parent?.properties?.collapsed &&
    parent.nodeType !== EfNodeType.Page
  ) {
    visible = null;
  } else if (parent?.computed.visible !== undefined) {
    visible = parent?.computed.visible;
  }

  return { path, visible, pathInPage };
}

async function updateComputedFields(node: ComputedFieldsInput) {
  await db.transaction("readwrite", db.nodes, async (tx) => {
    await updateComputedFieldsUtil(node, tx);
  });
}

const computeRootNode = async (
  node: EfNodeEditorData
): Promise<string | null> => {
  if (
    !node.parentId ||
    ![EfNodeType.Block, EfNodeType.Tag, EfNodeType.Task].includes(node.nodeType)
  ) {
    return null;
  }
  const parentNode = await db.nodes.where("id").equals(node.parentId!).first();
  if (!parentNode) {
    return null;
  }
  if ([EfNodeType.Page, EfNodeType.ThoughtPad].includes(parentNode?.nodeType)) {
    return parentNode.id;
  }
  return await computeRootNode(getNodeEditorData(parentNode));
};

async function updateComputedFieldsUtil(
  node: ComputedFieldsInput,
  tx?: Transaction
) {
  const computed = await getComputedFields(node, tx);
  await db.getTable("nodes", tx).update(node.id, { computed });
  const children = await db
    .getTable("nodes", tx)
    .where("parentId")
    .equals(node.id)
    .toArray();
  await Promise.all(
    children.map((child) => updateComputedFieldsUtil(child, tx))
  );
}

export async function updateRelatedNodesModifiedTimeIfRequired(
  nodeId: string,
  relatedNodesModifiedTimeStamp?: number
) {
  await db.transaction("readwrite", db.nodes, async () => {
    const tagIdSet: Set<string> = new Set();
    const mentionIdSet: Set<string> = new Set();
    let latestModifiedTime = 0;
    const latestModifiedTimeForFound: Record<string, number> = {
      tag: 0,
      contact: 0,
    };

    const nodes = (await getParentNodes(nodeId)).reverse();
    const lastNode = nodes.at(-1);
    if (lastNode?.nodeType === EfNodeType.Tag) {
      if (lastNode.deleted) {
        await deleteTagAndItsChildrenInCache(lastNode.id);
      } else {
        // Updating tag cache with last node which was created
        updateTagCacheForKey(lastNode.id, {
          ...lastNode,
          fullName: nodes.map(({ titleText }) => titleText).join("."),
        });
      }
    }
    nodes.forEach((node) => {
      if (!node) {
        return;
      }
      (node?.tagIds || []).forEach((tagId) => tagIdSet.add(tagId));
      (node?.mentionIds || []).forEach((mentionId) =>
        mentionIdSet.add(mentionId)
      );
      latestModifiedTime = Math.max(
        latestModifiedTime,
        node?.relatedNodesModifiedTimeStamp || 0,
        // TODO: use client modified time when that changes  to PROD PR: https://github.com/execfn/exec-world/pull/483
        node?.clientModifiedTime?.getTime?.() || 0,
        relatedNodesModifiedTimeStamp || 0
      );
      if (node.tagIds?.length) {
        latestModifiedTimeForFound.tag = Math.max(
          latestModifiedTimeForFound.tag,
          latestModifiedTime
        );
      }
      if (node.mentionIds?.length) {
        latestModifiedTimeForFound.contact = Math.max(
          latestModifiedTimeForFound.contact,
          latestModifiedTime
        );
      }
    });

    if (!mentionIdSet.size && !tagIdSet.size) {
      return;
    }

    await Promise.all(
      [...Array.from(tagIdSet), ...Array.from(mentionIdSet)].map(async (id) => {
        const nodes = await getParentNodes(id);
        await Promise.all(
          nodes.map(async (node, index) => {
            const latestNodeModifiedTime =
              latestModifiedTimeForFound[node.nodeType.toLocaleLowerCase()];
            if (
              node &&
              (node?.relatedNodesModifiedTimeStamp || 0) <=
                latestNodeModifiedTime
            ) {
              if (node.nodeType === EfNodeType.Tag && !node.deleted) {
                // Updating tag cache with relatedNodesModifiedTime
                updateTagCacheForKey(node.id, {
                  ...node,
                  relatedNodesModifiedTimeStamp:
                    latestModifiedTimeForFound[
                      node.nodeType.toLocaleLowerCase()
                    ],
                });
              }
              return await db.nodes.update(node.id, {
                relatedNodesModifiedTimeStamp: latestNodeModifiedTime,
              });
            }
          })
        );
      })
    );
  });
}
