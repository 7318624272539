import { Editor, Range } from "@tiptap/core";
import { useEditor } from "@tiptap/react";
import clsx from "clsx";
import { Selection, Transaction } from "prosemirror-state";
import { useEffect, useMemo, useReducer, useRef } from "react";
import invariant from "tiny-invariant";
import {
  calcNodeDepth,
  CustomExtension,
} from "../extensions/new-tiptap/CustomExtension";
import { EfNodeType } from "../graphql";
import { HistoryManager } from "../hooks/useHistoryManager";
import { EfContentWithIds, EfNode, FocusModeType } from "../types";
import { updateNode } from "../utils";
import { ClipboardEfNode } from "../utils/copy";
import { extractIds } from "../utils/extractIds";
import { NewTiptap } from "./NewTiptap";
import { DataTest } from "../../tests/e2e/utils/constants";
import { hideAll } from "tippy.js";
import { defaultNewTipTapExtensions } from "../extensions/new-tiptap";
import { db } from "@/db";
import { FocusMode } from "@/context/FocusMode";
import { TimeStampDecorationPlugin } from "@/extensions/new-tiptap/TimeStampDecorationPlugin";
import { getEditorId } from "./VirtualizedEditor/editorUtils/genericUtils";

export const NodeRendererClass = "node-renderer";

export type NewNodeRendererProps = {
  node: EfNode;
  rootNodeId: string;
  history: HistoryManager;
  focused: boolean;
  selectMode: boolean;
  focusType?: "start" | "end";
  isAreaSelection: boolean;
  selected: boolean;
  onArrowUp: (count?: number, handled?: boolean) => void;
  onArrowDown: (count?: number, handled?: boolean) => void;
  onArrowLeft: () => void;
  onArrowRight: () => void;
  onSink: () => void;
  onLift: () => void;
  onFocus: (e: FocusEvent) => void;
  onBlur: (e: FocusEvent) => void;
  onCreate: (
    partialExistingNode: EfContentWithIds | null,
    partialNewNode: EfContentWithIds,
    newSiblingDisabled?: boolean
  ) => void;
  onEscape: () => void;
  onDeleteBackward: () => void;
  onDeleteForward: () => void;
  onSelectForward: () => void;
  onSelectBackward: () => void;
  onPaste: (node: ClipboardEfNode[]) => void;
  getPreviousNodeTagIds: () => string[];
  toggleTask: (range: Range, editor: Editor) => void;
  showConfirmPastePopup: (onSubmit: (pasteAsList: boolean) => void) => void;
  onUpdate: (nodeElement: HTMLElement | null) => void;
  relativeDepth?: number;
  focusModeDetails?: FocusMode;
  highlighted?: boolean;
  addEndDecorationPlugin?: boolean;
  focusModeType?: FocusModeType;
};

export function NewNodeRenderer(props: NewNodeRendererProps) {
  invariant(props.node.computed.pathInPage);
  const depth = calcNodeDepth(props.node, props.relativeDepth);
  const nodeRef = useRef<HTMLDivElement>(null);

  const nodesFocusModeStyle = () => {
    if (!props.focusModeDetails) {
      return false;
    }
    const { activated, firstUnFocusedNode } = props.focusModeDetails;
    if (!activated) {
      return {};
    }
    // here we are calculating if this node should be shown in focussed mode.
    // for now we have only one mode i.e dim other blocks.
    const isFocussed =
      (firstUnFocusedNode?.computed.pathInPage || "") <=
      (props.node.computed.pathInPage || "");
    if (!isFocussed) {
      return {};
    }
    return {
      "opacity-50": props.focusModeType === FocusModeType.DIM_OTHER_BLOCKS,
      "opacity-0": props.focusModeType === FocusModeType.HIDE_OTHER_BLOCKS,
    };
  };

  const [counter, forceUpdate] = useReducer((counter) => counter + 1, 0);

  const customExtension = useMemo(() => CustomExtension.configure({}), []);
  const timeStampDecorationPlugin = useMemo(
    () => TimeStampDecorationPlugin.configure({}),
    []
  );
  // this is a "hack" to pass the props to our extension
  // otherwise, we would have to recreate the extension every time the props change
  // which would cause the editor to reinitialize and flicker
  Object.assign(customExtension.options, props);
  Object.assign(timeStampDecorationPlugin.options, props);

  const pendingUpdate = useRef<Update | null>(null);
  const extensions = [...defaultNewTipTapExtensions, customExtension];
  if (!!props.addEndDecorationPlugin) {
    extensions.push(timeStampDecorationPlugin);
  }

  const editor = useEditor({
    extensions: extensions,
    content: props.node.contentText,
    enableInputRules: extensions,
    enablePasteRules: extensions,
    parseOptions: {
      preserveWhitespace: "full",
    },
    onBlur: () => hideAll(),
    onCreate: () => {
      console.log("onCreate editor");
    },
    onUpdate: async ({ editor, transaction }) => {
      props.onUpdate(nodeRef.current);
      const localUpdate = new Update();
      pendingUpdate.current = localUpdate;

      const json = editor.getJSON();
      await onUpdate({
        content: editor.getHTML(),
        tagIds: extractIds(json, "tag"),
        mentionIds: extractIds(json, "mention"),
        referencedPageIds: extractIds(json, "PageRef"),
        fileIds: extractIds(json, "file"),
        transaction,
      });

      // sleep for debouncing
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // if this is the last update, we will reset the pending update
      if (pendingUpdate.current === localUpdate) {
        pendingUpdate.current = null;
        forceUpdate();
      }
    },
  });

  const hasVisibleChildren = async () => {
    const nodes = await db.nodes
      .where("[computed.visible+computed.pathInPage]")
      .aboveOrEqual([1, props.node.computed.pathInPage])
      .limit(2)
      .toArray();
    if (nodes[1]?.parentId === props.node.id) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    // if there is a pending update and node is focussed then do not update the editor
    // we added focused check because if user has unfocussed (meaning he is not typing) we can update editor content even if pendingUpdate timer is not finished.
    if (pendingUpdate.current && props.focused) return;

    if (!editor) return;
    if (editor.getHTML() === props.node.contentText) return;

    editor
      .chain()
      .setContent(props.node.contentText, false, { preserveWhitespace: "full" })
      .setTextSelection(editor.state.selection)
      .run();
  }, [editor, props.node.contentText, counter, props.focused]);

  useEffect(() => {
    if (!props.focused) return;
    if (!editor) return;
    editor.commands.scrollIntoView();
    editor.commands.focus();
    if (props.focusType === "start") {
      editor.commands.setTextSelection(Selection.atStart(editor.state.doc));
    } else if (props.focusType === "end") {
      editor.commands.setTextSelection(Selection.atEnd(editor.state.doc));
    }
  }, [props.focused, editor]);

  useEffect(() => {
    // Add editor to global state
    if (!editor) return;
    window.editors = window.editors ?? {};
    window.editors[props.node.id] = editor;
    return () => {
      window.editors[props.node.id] = undefined;
    };
  }, [editor]);

  const onUpdate = async ({
    content,
    mentionIds,
    tagIds,
    fileIds,
    referencedPageIds,
    transaction,
  }: {
    content: string;
    tagIds: string[];
    mentionIds: string[];
    fileIds: string[];
    referencedPageIds: string[];
    transaction: Transaction;
  }) => {
    // When ignore history is set to true will not run via history
    const ignoreHistory = transaction.getMeta("ignoreHistory");
    const update = async () => {
      await updateNode({
        ...props.node,
        contentText: content,
        mentionIds,
        fileIds,
        tagIds,
        referencedPageIds,
      });
    };
    if (ignoreHistory) {
      await update();
    } else {
      props.history.run({
        redo: async () => await update(),
        undo: async () => {
          await updateNode(props.node);
        },
      });
    }
  };

  let headingLevel = 0;

  if (
    editor &&
    editor.state.doc?.firstChild?.type === editor.schema.nodes.heading
  ) {
    headingLevel = editor.state.doc.firstChild.attrs?.level || 0;
  }

  return (
    <div
      data-nodeid={getEditorId(props.rootNodeId, props.node.id)}
      className={clsx("flex items-start", NodeRendererClass, {
        "add-padding": depth === 0,
        "mt-3":
          props.focusModeDetails?.activated &&
          props.focusModeDetails.firstUnFocusedNode?.id === props.node.id,
        ...nodesFocusModeStyle(),
      })}
      data-testid={DataTest.EfNode}
      style={{ backgroundColor: props.selected ? "#D6E6FA" : undefined }}
      ref={nodeRef}
    >
      <div style={{ width: depth * 20 }} />
      <div
        className={clsx(
          {
            ["!h-9"]: headingLevel === 1,
            ["!h-6"]: headingLevel === 2,
            ["!h-5"]: headingLevel === 3,
          },
          "h-4 mr-1 pt-4 space-x-2 flex items-center justify-center"
        )}
      >
        <button
          className={clsx(
            "w-[5px] h-[5px] rounded-full box-content border-[6px] bg-[#374151]",
            props.node.properties?.collapsed
              ? "border-gray-200"
              : "border-white"
          )}
          onClick={async () => {
            if (
              props.node.properties?.collapsed ||
              (await hasVisibleChildren())
            ) {
              updateNode({
                ...props.node,
                properties: {
                  ...props.node.properties,
                  collapsed: !props.node.properties?.collapsed,
                },
              });
            }
          }}
        >
          <span className={clsx("w-2 h-2 bg-gray-400 rounded-full")} />
        </button>
        {props.node.nodeType === EfNodeType.Task && (
          <input
            type="checkbox"
            className="h-4  mr-1"
            checked={props.node.properties?.taskStatus === "COMPLETED"}
            readOnly
            data-testid={DataTest.CheckBox}
            onClick={() => {
              updateNode({
                ...props.node,
                properties: {
                  ...props.node.properties,
                  taskStatus:
                    props.node.properties?.taskStatus === "COMPLETED"
                      ? "PENDING"
                      : "COMPLETED",
                },
              });
            }}
          />
        )}
      </div>
      <div className="w-full min-w-0">
        {editor && (
          <NewTiptap editor={editor} highlighted={props.highlighted} />
        )}
      </div>
    </div>
  );
}
// this is a dumb class with no real functionality
// we use it to create a unique instance each time we want to update the local db
// we use it only for reference comparison
class Update {}
