import { getParentNodes } from "@/utils/nodes";
import {
  getLastVisitedInSettings,
  setLastVisitedInSettings,
} from "@/utils/settings";
import { debounce } from "lodash";
import {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useResizeObserver } from "./useResizeObserver";
import { useProxyRef } from "./useProxyRef";
import { getNodeIdFromEditorId } from "@/components/VirtualizedEditor/editorUtils/genericUtils";
import { useKeyboardShortcut } from "./useKeyboardShortcut";

type FocusNodeProps = {
  pageId: string; // the id of the page for which lastVisitedEditor needs to be calculated
  ids?: string[]; // ids is the tag(+ descendets)ids for tag page, contactId for contact page and pending/completed for pending/completed page
  enabled: boolean; // Boolean variable to enable or disable the focussing
  scrollRef: RefObject<HTMLDivElement>; // scrollRef of the scroll container
  stateChangeCountRef: MutableRefObject<number>; // We need to focus the node after it has been pulled, but not when the data has been updated,
  // so intially the state is 0, and once fetched it will be 1, we then focus, if the node is updated the state will be 2 and we will ignore and not focus again
};

export const useFocusNode = (props: FocusNodeProps) => {
  const [nodeFocussed, setNodeFocussed] = useState<boolean>(false);
  const [nodeToFocus, setNodeToFocus] = useState<string>();
  // proxy refs to access in focusnode
  const propsRef = useProxyRef(props);
  const nodeToFocusRef = useProxyRef(nodeToFocus);
  const nodeFocussedRef = useProxyRef(nodeFocussed);
  const { isKeyboardShortcut } = useKeyboardShortcut({
    keys: [{ or: ["PageUp", "PageDown"] }],
    element: window,
  });

  useEffect(() => {
    const findNodeToFocus = async () => {
      // if not enabled then skip this effect processing
      if (!props.enabled) {
        return;
      }
      const lastVisitedEditorIds = await getLastVisitedInSettings(props.pageId);
      // if nothing is present in lastVisitedEditorIds then skip
      if (!lastVisitedEditorIds?.length) {
        setNodeFocussed(true);
        return;
      }
      for (const lastVisitedEditorId of lastVisitedEditorIds) {
        const nodeId = getNodeIdFromEditorId(lastVisitedEditorId);
        // getting all the parent nodes
        const parentNodes = await getParentNodes(nodeId);
        if (!parentNodes?.length) {
          continue;
        }
        // getting tagIds, mentionIds and taskStatuses from all the nodes
        const { tagIds, mentionIds, taskStatuses } = parentNodes
          .filter(({ deleted }) => !deleted)
          .reduce<{
            tagIds: string[];
            mentionIds: string[];
            taskStatuses: string[];
          }>(
            (accumulator, efNode) => {
              accumulator.taskStatuses.push(
                efNode.properties?.taskStatus?.toLowerCase?.() || ""
              );
              return {
                tagIds: accumulator.tagIds.concat(efNode.tagIds || []),
                mentionIds: accumulator.mentionIds.concat(
                  efNode.mentionIds || []
                ),
                taskStatuses: accumulator.taskStatuses,
              };
            },
            { tagIds: [], mentionIds: [], taskStatuses: [] }
          );
        // checking if ids are matching with any of tagIds/contactIds/taskStatuses calculated above
        // 1. TagIds:- current+child
        // 2. ContactIds:- currentId
        // 3 Tasks:- taskName(i.e pending or completed)
        const matching = !!(props.ids || []).find(
          (id) =>
            (tagIds || []).includes(id) ||
            (mentionIds || []).includes(id) ||
            (taskStatuses || []).includes(id.toLowerCase())
        );
        // if present then that means that node should be focussed
        if (matching) {
          setNodeToFocus(lastVisitedEditorId);
          break;
        }
      }
      return;
    };
    findNodeToFocus();
  }, [props.pageId, props.enabled, props.ids]);

  const debouncedOnScroll = useCallback(
    debounce((id: string) => {
      const nodeElements =
        document?.querySelectorAll<HTMLElement>(`[data-nodeid]`);
      if (!nodeElements) {
        return;
      }
      const nodesArr = Array.from(nodeElements);
      const firstNodeInViewportIndex = nodesArr.findIndex((nodeElement) => {
        const { top, height } = nodeElement.getBoundingClientRect();
        const iosTopPadding = parseInt(
          getComputedStyle(document.documentElement).getPropertyValue(
            "--safe-area-inset-top"
          ) || "0"
        );
        return top - 48 - iosTopPadding + height / 2 > 0;
      });
      setLastVisitedInSettings(
        id,
        nodesArr
          .slice(firstNodeInViewportIndex, firstNodeInViewportIndex + 5)
          .map((element) => element.getAttribute("data-nodeid") || "")
      );
    }, 500),
    []
  );
  const onScroll = useCallback(
    () => debouncedOnScroll(props.pageId),
    [props.pageId]
  );

  useEffect(() => {
    return debouncedOnScroll.cancel;
  }, []);

  const focusToNode = async () => {
    const { enabled, stateChangeCountRef, scrollRef } = propsRef.current;
    // if on is false and nodeToFocus is not present then dont process focus node.
    if (!enabled || !nodeToFocusRef.current) {
      return;
    }
    // if nodeFocussed is false or stateChangeCount is equals 1
    // if any of above is true the we will focus to that node above cases
    if (!nodeFocussedRef.current || stateChangeCountRef.current === 1) {
      // find node with id in DOM
      const nodeElement = scrollRef.current?.querySelector(
        `[data-nodeid="${nodeToFocusRef.current}"]`
      );
      // if rendered in DOM then scroll to it.
      if (nodeElement) {
        nodeElement.scrollIntoView({ behavior: "instant", block: "start" });
        if (!nodeFocussedRef.current) {
          setNodeFocussed(true);
        }
      }
    }
  };

  const observer = useResizeObserver(focusToNode);

  // added this useEffect due to the reason if enabled is not true or notToFocus is not set yet and by that time all the nodes are rendered and
  // then there is no change in height then resizeObser won't trigger so in this case useEffect will help here.
  useEffect(() => {
    focusToNode();
  }, [props.enabled, nodeToFocus]);

  useEffect(() => {
    const eventListener = () => {
      if (nodeFocussedRef.current) {
        props.stateChangeCountRef.current += 1;
      }
    };
    document.addEventListener("wheel", eventListener);
    document.addEventListener("touchmove", eventListener);
    return () => {
      document.removeEventListener("wheel", eventListener);
      document.addEventListener("touchmove", eventListener);
    };
  }, []);

  useEffect(() => {
    if (
      isKeyboardShortcut &&
      props.stateChangeCountRef &&
      nodeFocussedRef.current
    ) {
      props.stateChangeCountRef.current += 1;
    }
  }, [isKeyboardShortcut]);

  return {
    onScroll,
    nodeFocussed,
    observer,
  };
};
