import { Extension } from "@tiptap/react";
import {
  differenceInMinutes,
  format,
  isEqual,
  isThisYear,
  isToday,
} from "date-fns";
import { Plugin, Selection } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { toZonedTime } from "date-fns-tz";
import { Node } from "@tiptap/pm/model";
import { EfNode } from "@/types";
import { CalendarIcon } from "@/components/Icons";
import { createRoot } from "react-dom/client";
import { flushSync } from "react-dom";

const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
function timeStampDomNode(node: EfNode, docChanged: boolean) {
  const span = document.createElement("span");
  if (!node.clientModifiedTime && !node.clientTimestamp) {
    return span;
  }
  span.className = "text-slate-500 ml-2  text-xs inline-block";
  if (docChanged) {
    span.innerText = "Just Now";
    return span;
  }
  const localDate = node.clientModifiedTime
    ? toZonedTime(
        format(node.clientModifiedTime, "yyyy-MM-dd'T'HH:mm:ss"),
        currentTimeZone
      )
    : null;
  const localClientTimeStampDate = toZonedTime(
    new Date(node.clientTimestamp || 0),
    currentTimeZone
  );
  const date = (node.clientTimestamp ? localClientTimeStampDate : localDate)!;
  const minutes = differenceInMinutes(new Date(), date);
  let finalDateToShow = "";
  if (isToday(date)) {
    if (minutes === 0) {
      finalDateToShow = "Just Now";
    } else if (minutes === 1) {
      finalDateToShow = minutes + "min ago";
    } else if (minutes < 60) {
      finalDateToShow = minutes + "mins ago";
    } else {
      finalDateToShow = format(date, "hh:mm aaa");
    }
  } else if (isThisYear(date)) {
    finalDateToShow = format(date, "d MMM");
  } else {
    finalDateToShow = format(date, "MMM dd, yyyy");
  }
  const root = createRoot(span);
  flushSync(() => {
    root.render(
      <span className="flex items-center space-x-1">
        {minutes !== 0 && <CalendarIcon />}
        <span>{finalDateToShow}</span>
      </span>
    );
  });
  return span;
}

const getDecorationsSet = (doc: Node, node: EfNode, docChanged: boolean) => {
  return DecorationSet.create(doc, [
    Decoration.widget(
      Selection.atEnd(doc).from,
      timeStampDomNode(node, docChanged)
    ),
  ]);
};

export const TimeStampDecorationPlugin = Extension.create({
  name: "TimeStampDecoration",
  addProseMirrorPlugins() {
    const { options } = this;
    return [
      new Plugin({
        state: {
          init(_, { doc }) {
            return {
              clientModifiedTime: options.node.clientModifiedTime,
              clientTimestamp: options.node.clientTimestamp,
              decorations: getDecorationsSet(doc, options.node, false),
            };
          },
          apply(tr, old) {
            const isStateChanged =
              tr.docChanged ||
              !isEqual(
                old.clientModifiedTime,
                options.node.clientModifiedTime
              ) ||
              old.clientTimestamp !== options.node.clientTimestamp;
            return {
              decorations: isStateChanged
                ? getDecorationsSet(tr.doc, options.node, tr.docChanged)
                : old.decorations,
              clientModifiedTime: options.node.clientModifiedTime,
              clientTimestamp: options.node.clientTimestamp,
            };
          },
        },
        props: {
          decorations(state) {
            return this.getState(state)?.decorations;
          },
        },
      }),
    ];
  },
});
