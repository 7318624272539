import Base from "@tiptap/extension-mention";
import { ReactNodeViewRenderer, ReactRenderer } from "@tiptap/react";
import { SuggestionOptions } from "@tiptap/suggestion";
import { PluginKey } from "prosemirror-state";
import tippy, { Instance } from "tippy.js";
import { MentionRenderer } from "../components/MentionRenderer";
import { SuggestionList } from "../components/SuggestionList";
import { db } from "../db";
import { EfNodeType } from "../graphql";

const MentionPluginKey = new PluginKey("mention");

export const queryMentions = async (query: string) => {
  const contacts = await db.nodes
    .where("nodeType")
    .equals(EfNodeType.Contact)
    .toArray();
  return contacts
    .filter((item) => !item.deleted)
    .map((item) => ({ id: item.id, label: item.titleText }))
    .filter((item) => item.label?.toLowerCase().startsWith(query.toLowerCase()))
    .slice(0, 12);
};

export const suggestion: Omit<SuggestionOptions, "editor"> = {
  char: "@",
  pluginKey: MentionPluginKey,
  items: async ({ query }) => queryMentions(query),
  render: () => {
    let reactRenderer: ReactRenderer;
    let popup: Instance[];

    return {
      onStart: (props) => {
        reactRenderer = new ReactRenderer(SuggestionList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect || !props.editor.isFocused) {
          return;
        }

        // TODO
        // @ts-ignore
        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: reactRenderer.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
        });
      },

      onUpdate(props) {
        reactRenderer?.updateProps(props);

        if (!popup) return;

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          // @ts-ignore
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        if (!popup) return;

        if (props.event.key === "Escape") {
          popup[0].hide();

          return true;
        }

        if (popup[0].state.isVisible) {
          // @ts-ignore
          return reactRenderer?.ref?.onKeyDown(props);
        }
      },

      onExit() {
        popup?.[0]?.destroy();
        reactRenderer?.destroy();
      },
    };
  },
};

export const Mention = Base.extend({
  name: "mention",
  priority: 100000,
  addNodeView() {
    return ReactNodeViewRenderer(MentionRenderer, {
      update: ({ oldNode, newNode }) => {
        if (oldNode.attrs.id !== newNode.attrs.id) {
          // Ignore update in case prosemirror try to update with other tag
          return false;
        }
        return true;
      },
    });
  },
  renderHTML({ node }) {
    return ["span", { "data-type": this.name, "data-id": node.attrs.id }];
  },
}).configure({
  suggestion,
  HTMLAttributes: {
    spellcheck: false,
  },
});
